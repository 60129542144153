<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();
const { data: appInitData } = useAppInitData();
const { depositInfoData, timerWheelDisabled, locizeWheelOfWins, imgWheelOfWins } = useDepositStreakData();

defineProps<{
	title: string;
	subTitle?: string;
	type?: Promotion["type"];
	image: string;
}>();

const buttonName = computed(() => {
	if (appInitData.value?.isGuest) {
		return t("Register & play");
	}
	if (depositInfoData?.value?.secondsToResetStreakCounter) {
		return timerWheelDisabled.value;
	}
	if (depositInfoData?.value?.canWheel) {
		return t("Spin & Win");
	}
	return t("Make purchase");
});

const handleClick = () => {
	if (depositInfoData?.value?.secondsToResetStreakCounter) {
		return;
	}
	dispatchGAEvent({
		event: "click_button",
		button_name: "make_purchase",
		location: "promotions"
	});
	if (depositInfoData?.value?.canWheel) {
		open("LazyOModalWheelOfWinsPlayWheel");
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleClickInfo = () => {
	loginGuard({
		success: () => {
			open("LazyOModalWheelOfWinsHowItWorks");
		},
		fail: () => open("LazyOModalSignup")
	});
};

const finishedAt = computed(() => {
	if (depositInfoData?.value?.finishedAt && depositInfoData?.value?.finishedAt !== "0000-00-00 00:00:00") {
		return depositInfoData?.value?.finishedAt;
	}
	return appInitData?.value?.depositStreak?.finishedAt;
});
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="t(`${locizeWheelOfWins}.Name`) || title"
		:image="`${imgWheelOfWins}/promo.jpg` || image"
		buttonInfo
		:buttonName="buttonName"
		:buttonDisabled="!!depositInfoData.secondsToResetStreakCounter"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<template #top>
			<MWheelOfWinsFakeWheel transparent showElements />
		</template>

		<AText class="description" :size="16" :modifiers="['light']" as="div">
			{{ t(`${locizeWheelOfWins}.promo.description`) || subTitle }}
		</AText>

		<MWheelOfWinsStepsProgressBar showNumber />

		<MCounterBadgeGroup
			v-if="finishedAt"
			:timestamp="finishedAt ?? ''"
			:badgeText="t(`${locizeWheelOfWins}.countdownLabel`)"
		/>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.card-depositStreak {
	.fake-wheel {
		position: absolute;
		width: 260px;
		height: 260px;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		&.transparent :deep(.inner-shell) {
			mask-image: linear-gradient(
				180deg,
				rgba(0, 0, 0, 1) 0,
				rgba(0, 0, 0, 1) 30%,
				rgba(0, 0, 0, 0) 50%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}
	:deep(.title),
	.description {
		text-shadow: 0 0 2px rgba(var(--neutral-0-rgb), 0.6);
	}

	.progress {
		margin: 8px 0;
	}
}
</style>
